<template>
  <div class="d-flex justify-content-between flex-wrap">
    <div style="width: 100%" id="investments" class="mr-1">
      <apexchart type="bar" height="200" :options="investments.chartOptions"
                 :series="getDataInvestments"></apexchart>
    </div>
    <div style="width: 100%" id="capitalization">
      <apexchart type="bar" height="200" :options="capitalizationChart.chartOptions"
                 :series="getDataCapitalization"></apexchart>
    </div>
    <div style="width: 100%" >
        <apexchart type="donut" width="380" :options="balance.chartOptions" :series="balance.series"></apexchart>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: 'Charts',
  components: {},
  data() {
    return {
      investments: {
        chartOptions: {
          chart: {
            type: 'bar',
            height: 200
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            categories: ['Инвестиции', ['Получение', 'кредитов и', 'займов'], ['Вклады от', 'собственников']],
          }

        }
      },
      capitalizationChart: {
        chartOptions: {
          chart: {
            type: 'bar',
            height: 200
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
            },
            style: {
              colors: ['#d78b8b']
            }
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            categories: [['Остатки по', 'счетам из CF'], ['Товарный', 'остаток на МП'], ['Предоплаты', 'за товар из CF']],
          }

        }
      },
      balance: {
        series: [180000, 50000, 1000000],
        chartOptions: {
          chart: {
            width: 380,
            type: 'donut',
          },
          labels: ['Реинвест', ['Остатки', 'МП'], ['Остатки', 'склад']],
          dataLabels: {
            enabled: true
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
            }
          }],
          legend: {
            position: 'right',
            offsetY: 0,
            height: 230,
          }
        },
      },
    }
  },

  computed: {

    getDataInvestments() {
      return [{
        data: [+this.totalSummaInvestments || 0, +this.totalSummaCredit || 0, +this.totalSummaBusinessOwner || 0]
      }]
    },

    getDataCapitalization() {
      return [{
        data: [+this.capitalization || 0, +this.inventoryBalance || 0, +this.prepaymentForGoods || 0]
      }]
    },

    ...mapState('charts', [
      "capitalization",
      "inventoryBalance",
      "prepaymentForGoods",
      "totalSummaCredit",
      "totalSummaExecutionCredit",
      "totalSummaBusinessOwner",
      "totalSummaExecutionBusinessOwner",
      "totalSummaInvestments",
      "totalSummaExecutionInvestments",
    ])
  },

  methods: {
    ...mapActions('charts', ["getInvestment", "getCapitalization"])
  },

  async mounted() {
    await this.getCapitalization()
    await this.getInvestment()
  }
}
</script>